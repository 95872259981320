import { useState, useRef } from "react";

import { collection, where, getDocs, query, addDoc } from "firebase/firestore";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import axios from "axios";

import { db } from "../../firebase";

export default function ResChambre() {
    const [resError, setResError] = useState("");
    const resRef = collection(db, "res_hotel");
    const [suivant, setSuivant] = useState(false);

    const [newName, setNewName] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newDate, setNewDate] = useState();

    const [petitDej, setPetitDej] = useState(false);

    const [sentBtn, setSentBtn] = useState(false);

    const personRef = useRef();

    let selectedRange = [];

    var h = document.querySelectorAll(".react-calendar__tile--active abbr");
    if (h.length > 0) {
        var range = [].slice.call(h);
        var p = range.map(function (el) {
            return el.ariaLabel;
        });

        selectedRange.push(p);
        var aa = selectedRange[0][0].substr(selectedRange[0][0].indexOf(" ") + 1);
        var zz = aa.substring(0, aa.lastIndexOf(" "));
    }

/*     const offDate1 = new Date("2023-08-08").getDate();
    const offDate2 = new Date("2023-08-09").getDate();
    const offDate3 = new Date("2023-08-10").getDate();
    const offDate4 = new Date("2023-08-11").getDate();
    const offDate5 = new Date("2023-08-12").getDate();
    const offDate6 = new Date("2023-08-13").getDate();
    const offDate7 = new Date("2023-08-14").getDate();
    const offDate8 = new Date("2023-08-15").getDate();
    const offDate9 = new Date("2023-08-16").getDate();
    const offDate10 = new Date("2023-08-17").getDate();
    const offDate11 = new Date("2023-08-18").getDate();
    const offDate12 = new Date("2023-08-19").getDate();
    const offDate13 = new Date("2023-08-20").getDate();
    const offDate14 = new Date("2023-08-21").getDate();

    const offDates = [
        offDate1,
        offDate2,
        offDate3,
        offDate4,
        offDate5,
        offDate6,
        offDate7,
        offDate8,
        offDate9,
        offDate10,
        offDate11,
        offDate12,
        offDate13,
        offDate14,
    ]; */

    var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

    const handleNext = () => {
        if (!newDate) {
            return setResError("Date pas valide");
        }

        if (newDate.length < 1) {
            return setResError("Date de départ manquante");
        }

        setTimeout(() => {
            setSuivant(true);
        }, 200);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        let arr = [];

        const q = query(resRef, where("date", "array-contains-any", selectedRange[0]));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
            arr.push({ ...doc.data(), id: doc.id });
        });

        if (arr) {
            var everyone = arr.reduce((prev, curr) => {
                return prev + +curr.adultes;
            }, 0);
        }

        if (everyone >= 16) {
            return setResError("Complet pour ces dates");
        }

        if (+everyone + +personRef.current.value >= 16) {
            return setResError("Complet pour ces dates");
        }

        if (newName.length <= 3) {
            return setResError("Nom pas valide");
        }

        if (personRef.current.value === 0) {
            return setResError("Nombre de personnes pas valide");
        }

        if (newPhone.length <= 8) {
            return setResError("Téléphone pas valide");
        }

        if (reg.test(newEmail) === false) {
            return setResError("Email pas valide");
        }

        if (!newDate) {
            return setResError("Date pas valide");
        }

        const newOptions = {
            year: "numeric",
            month: "long",
            day: "2-digit",
        };

        if (petitDej === true) {
            var pDej = "oui";
        } else {
            pDej = "non";
        }

        var date1 = selectedRange[0][0];
        var date2 = selectedRange[0][selectedRange[0].length - 1];
        var ppl = personRef.current.value;

        try {
            await addDoc(resRef, {
                name: newName,
                adultes: personRef.current.value,
                phone: newPhone,
                email: newEmail,
                petitDej: pDej,
                jours: selectedRange[0].length,
                arrive: selectedRange[0][0],
                depart: selectedRange[0][selectedRange[0].length - 1],
                date: selectedRange[0],
                dateResa: new Date().toLocaleDateString("fr-FR", newOptions),
                heureResa: new Date().getHours() + ":" + new Date().getMinutes(),
                mois: zz,
                year: selectedRange[0][0].split(" ").pop(),
                chambre: 0,
            });
            setSentBtn(true);
            setSuivant(false);
            setNewDate();
            e.target.reset();
        } catch (error) {
            console.log(error);
            setResError("Backend error");
        }

        try {
            await axios.post("https://bbmk.herokuapp.com/reservation_hotel", {
                newName,
                newEmail,
                newPhone,
                date1,
                date2,
                ppl,
                pDej,
            });
        } catch (error) {
            console.log(error);
        }
    };

    if (sentBtn) {
        setTimeout(() => {
            setSentBtn(false);
        }, 4000);
    }

    if (resError.length >= 2) {
        setTimeout(() => {
            setResError("");
        }, 4000);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className={
                    sentBtn
                        ? "relative -z-10 max-h-[0px] w-full overflow-hidden opacity-0 transition-all duration-500 ease-in-out"
                        : "relative z-10 h-screen w-full overflow-y-auto opacity-100 transition-all duration-500 ease-in-out md:h-auto md:max-h-[700px]"
                }
            >
                {suivant ? (
                    <button
                        onClick={() => setSuivant(false)}
                        className="mb-3 rounded-lg bg-slate-200 p-2 px-3 font-medium hover:bg-slate-300"
                    >
                        <i className="fa-solid fa-arrow-left mr-2 text-teal-500"></i>
                        Changez la date
                    </button>
                ) : (
                    ""
                )}

                <div
                    className={
                        suivant
                            ? "flex h-[330px] w-full flex-col items-center justify-center overflow-hidden opacity-100 transition-all duration-500 ease-in-out"
                            : "flex h-[0px] w-full flex-col items-center justify-center overflow-hidden opacity-0 transition-all duration-500 ease-in-out"
                    }
                >
                    <input
                        type="text"
                        placeholder="Nom"
                        onChange={e => {
                            setNewName(e.target.value);
                        }}
                        required
                        className="mb-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />
                    <input
                        type="number"
                        placeholder="Personnes"
                        min={1}
                        max={16}
                        ref={personRef}
                        required
                        className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />
                    <input
                        type="tel"
                        placeholder="Téléphone"
                        onChange={e => {
                            setNewPhone(e.target.value);
                        }}
                        required
                        className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={e => {
                            setNewEmail(e.target.value);
                        }}
                        required
                        className="mt-3 w-full rounded-xl border-2 border-slate-300 p-3"
                    />
                    <label className="mt-5 inline-flex items-center">
                        <input
                            onChange={() => setPetitDej(prev => !prev)}
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-yellow-500 checked:bg-yellow-500 focus:ring-0"
                        />
                        <span className="ml-2">Inclure le petit déjeuner (12€/personne)</span>
                    </label>
                </div>

                <div
                    className={
                        suivant
                            ? "max-h-[0px] w-full overflow-hidden opacity-0 transition-all duration-500 ease-in-out"
                            : "max-h-[600px] w-full overflow-hidden opacity-100 transition-all duration-500 ease-in-out"
                    }
                >
                    <div className="flex w-full items-center justify-between">
                        <div className="w-[100%]">
                            <Calendar
                                minDate={new Date()}
                                locale="fr-FR"
                                selectRange
                                onChange={date => setNewDate(date)}
                                /* tileDisabled={({ date }) => offDates.includes(date.getDate())} */
                            />
                        </div>
                    </div>
                    <div
                        className={
                            !newDate
                                ? "mt-5 flex max-h-[0px] w-full flex-col overflow-hidden transition-all duration-300 ease-in-out"
                                : "mt-5 flex max-h-[400px] w-full flex-col overflow-hidden transition-all duration-300 ease-in-out"
                        }
                    ></div>
                </div>

                {suivant ? (
                    <button
                        type="submit"
                        className={
                            resError === ""
                                ? "mt-5 w-full transform rounded-full bg-teal-600 p-3 text-sm font-semibold uppercase tracking-wider text-white transition-all duration-300 ease-in-out hover:-translate-y-2 hover:bg-teal-700"
                                : "mt-5 w-full transform cursor-not-allowed rounded-full bg-red-500 p-3 text-sm font-semibold uppercase tracking-wide text-white transition-all duration-300 ease-in-out"
                        }
                    >
                        {resError === "" ? "réserver" : <span>{resError}</span>}
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={handleNext}
                        className={
                            resError === ""
                                ? "mt-5 w-full transform rounded-full bg-teal-600 p-3 text-sm font-semibold uppercase tracking-wider text-white transition-all duration-300 ease-in-out hover:-translate-y-2 hover:bg-teal-700"
                                : "mt-5 w-full transform cursor-not-allowed rounded-full bg-red-500 p-3 text-sm font-semibold uppercase tracking-wide text-white transition-all duration-300 ease-in-out"
                        }
                    >
                        {resError === "" ? "suivant" : <span>{resError}</span>}
                    </button>
                )}
            </form>
            <div
                className={
                    sentBtn
                        ? "visible-check flex h-[250px] flex-col items-center justify-center opacity-100 transition-all duration-300 ease-in-out"
                        : "flex h-[0px] flex-col items-center justify-center overflow-hidden opacity-0 transition-all duration-300 ease-in-out"
                }
            >
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
                <h4 className="my-7 text-2xl font-medium">Réservation effectuée!</h4>
            </div>
        </>
    );
}
