import React from "react";
import { useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";

import Mobiletoggle from "../Mobiletoggle";

import { RiLogoutCircleRLine, RiMenu2Fill } from "react-icons/ri";

export default function Topbar({ setToggleMenu }) {
	const location = useLocation();
	const logout = async () => {
		await signOut(auth);
	};

	return (
		<div className="sticky top-0 left-0 mb-8 flex w-full items-center justify-between bg-white p-5 shadow-xl dark:bg-slate-700">
			<div className="flex items-center">
				<RiMenu2Fill
					onClick={() => setToggleMenu(prev => !prev)}
					className="mr-3 cursor-pointer text-2xl transition-all duration-200 hover:text-teal-600 dark:text-white"
				/>

				<h2 className="text-xl md:text-2xl font-semibold text-teal-600 dark:text-teal-500">
					{location.pathname === "/admin" && "Menu dejeuner"}
					{location.pathname === "/admin/brunch" && "Menu brunch"}
					{location.pathname === "/admin/soir" && "Menu soir"}
					{location.pathname === "/admin/evenements" && "Menu evenements"}
					{location.pathname === "/admin/textes" && "Modifier les paragraphes"}
					{location.pathname === "/admin/avis" && "Les avis"}
					{location.pathname === "/admin/restaurant" && "Réservations restaurant"}
					{location.pathname === "/admin/hotel" && "Réservations hotel"}
					{location.pathname === "/admin/rapport" && "Génerer un rapport"}
				</h2>
			</div>
			<div>
				<div className=" flex items-center justify-between space-x-3">
					<Mobiletoggle />
					<button
						onClick={logout}
						className="group flex items-center justify-center rounded-full bg-transparent p-1 text-slate-600 transition-all duration-300 hover:bg-teal-500 hover:text-white dark:text-slate-300 dark:hover:bg-teal-400 dark:hover:text-white"
					>
						<RiLogoutCircleRLine className="text-2xl" />
					</button>
				</div>
			</div>
		</div>
	);
}
