import React, { useState, useRef, useEffect } from "react";

import axios from "axios";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function HotelBtn({ customer, onDelete }) {
	const [sentAvis, setSentAvis] = useState(false);
	const [room, setRoom] = useState();
	const [id, setId] = useState();

	const emailRef = useRef();

	const sendEmail = async e => {
		e.preventDefault();

		var email = emailRef.current.value;

		try {
			await axios.post("https://bbmk.herokuapp.com/avis", {
				email,
			});
		} catch (error) {
			console.log(error);
		}

		console.log("sent");
		setSentAvis(true);
	};

	const [roomAdded, setRoomAdded] = useState(false);
	const addRoom = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "res_hotel", id);
		await updateDoc(chosenDoc, {
			adultes: customer.adultes,
			email: customer.email,
			date: customer.date,
			phone: customer.phone,
			name: customer.name,
			chambre: parseInt(room),
		});

		setRoomAdded(true);
	};

	useEffect(() => {
		setRoom(parseInt(customer.chambre));
	}, [customer.chambre]);

	if (roomAdded) {
		setTimeout(() => {
			setRoomAdded(false);
		}, 3000);
	}

	return (
		<div
			key={customer.id}
			className="flex w-[1400px] text-sm items-center justify-between border-b border-gray-400 p-3 px-6 dark:text-white 2xl:w-full"
		>
			<div className="flex items-center">
				<h5 className="font-bold">Client : </h5>
				<p className="ml-2 capitalize">{customer.name}</p>
			</div>
			<div className="flex items-center">
				<h5 className="font-bold">Personnes : </h5>
				<p className="ml-2">{customer.adultes}</p>
			</div>
			<div className="flex items-center">
				<h5 className="font-bold">Réservation : </h5>
				<p className="ml-2">
					du {customer.arrive} au {customer.depart}
				</p>
			</div>
			<div className="flex items-center">
				<h5 className="font-bold">Petit Dej : </h5>
				<p className="ml-2">{customer.petitDej}</p>
			</div>
			<form onSubmit={addRoom} className="flex items-center">
				<h5 className="font-bold text-yellow-500">Chambre : </h5>
				<input
					min={0}
					value={room}
					onChange={e => {
						setRoom(e.target.value);
						setId(customer.id);
					}}
					required
					type="number"
					placeholder="Chambre"
					className="ml-2 w-2/5 rounded bg-gray-100 p-1 px-2 capitalize dark:bg-gray-700 dark:text-white"
				/>
				<div className="relative flex items-center justify-center">
					{roomAdded ? (
						<span className="absolute -top-10 w-[120px] rounded bg-gray-200 text-center text-black">
							Ajouté{" "}
							<i className="fa-solid fa-circle-check text-green-500"></i>
						</span>
					) : (
						""
					)}
					<button
						type="submit"
						className="ml-2 rounded bg-green-600 p-1 px-2 capitalize"
					>
						<i className="fa-solid fa-clipboard-check"></i>
					</button>
				</div>
			</form>
			<div className="flex items-center">
				<form onSubmit={sendEmail}>
					<input
						ref={emailRef}
						type="text"
						className="hidden"
						defaultValue={customer.email}
						name="user_email"
					/>
					<button
						type="submit"
						className={
							sentAvis
								? "cursor-not-allowed border border-transparent bg-transparent p-1 px-4 hover:bg-transparent"
								: "border border-transparent bg-yellow-600 p-1 px-4 hover:border-yellow-600 hover:bg-transparent "
						}
					>
						{sentAvis ? (
							<span>
								<i className="fa-solid fa-circle-check text-green-500"></i>{" "}
								Envoyé
							</span>
						) : (
							<i className="fa-solid fa-paper-plane"></i>
						)}
					</button>
				</form>
				<button
					onClick={() => onDelete(customer)}
					className="ml-3 border border-transparent bg-red-600 p-1 px-4 hover:border-red-600 hover:bg-transparent"
				>
					<i className="fa-solid fa-trash-can"></i>
				</button>
			</div>
		</div>
	);
}
