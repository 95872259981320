import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";

import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";

import { MdHotel } from "react-icons/md";
import { IoRestaurantSharp } from "react-icons/io5";

export default function Report() {
	const [selectedMonth, setSelectedMonth] = useState("");
	const [selectedYear, setSelectedYear] = useState("");
	const [active, setActive] = useState(0);
	const [retrieved, setRetrieved] = useState([]);
	const [years, setYears] = useState([]);

	const [done, setDone] = useState(false);

	const handleSubmit = async e => {
		e.preventDefault();

		var q;

		if (active === 1) {
			q = query(
				collection(db, "res_hotel"),
				where("mois", "==", selectedMonth.toLowerCase()),
				where("year", "==", parseInt(selectedYear))
			);
		}

		if (active === 0) {
			q = query(
				collection(db, "res_restaurant"),
				where("mois", "==", selectedMonth.toLowerCase()),
				where("year", "==", parseInt(selectedYear))
			);
		}
		const querySnapshot = await getDocs(q);
		const docs = querySnapshot.docs.map(doc => {
			setRetrieved(oldArray => [...oldArray, { ...doc.data(), id: doc.id }]);
		});

		setDone(true);
	};

	const rangeOfYears = (start, end) =>
		Array(end - start + 1)
			.fill(start)
			.map((year, index) => year + index);

	useEffect(() => {
		setYears(rangeOfYears(2022, new Date().getFullYear()));
	}, []);

	return (
		<div className="flex w-full flex-col items-center justify-center">
			<form onSubmit={handleSubmit} className="flex w-[600px] flex-col items-center justify-center">
				<div className="my-5 flex w-full items-center justify-center space-x-6">
					<button
						type="button"
						onClick={() => setActive(0)}
						className={
							active === 0
								? "flex w-[200px] items-center justify-center rounded-xl bg-teal-600 px-6 py-3 font-medium text-yellow-400 shadow-lg shadow-teal-700 transition-all duration-300"
								: "flex w-[200px] items-center justify-center rounded-xl px-6 py-3 font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-500 dark:text-white dark:hover:bg-slate-600"
						}
					>
						<IoRestaurantSharp className="mr-2 text-xl" /> Restaurant
					</button>
					<button
						type="button"
						onClick={() => setActive(1)}
						className={
							active === 1
								? "flex w-[200px] items-center justify-center rounded-xl bg-teal-600 px-6 py-3 font-medium text-yellow-400 shadow-lg shadow-teal-700 transition-all duration-300"
								: "flex w-[200px] items-center justify-center rounded-xl px-6 py-3 font-medium text-black transition-all duration-300 hover:bg-slate-200 hover:text-teal-500 dark:text-white dark:hover:bg-slate-600"
						}
					>
						<MdHotel className="mr-2 text-xl" /> Hotel
					</button>
				</div>
				<div className="flex w-full items-center justify-center space-x-6">
					<select
						defaultValue={selectedMonth}
						onChange={e => setSelectedMonth(e.target.value)}
						name="month"
						id="month"
						className="my-2 cursor-pointer rounded-lg border bg-gray-100 p-3 pr-20 text-sm capitalize outline-none dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					>
						<option value="" disabled>
							Mois
						</option>
						<option value="janvier">janvier</option>
						<option value="février">février</option>
						<option value="mars">mars</option>
						<option value="avril">avril</option>
						<option value="mai">mai</option>
						<option value="juin">juin</option>
						<option value="juillet">juillet</option>
						<option value="août">août</option>
						<option value="février">septembre</option>
						<option value="octobre">octobre</option>
						<option value="novembre">novembre</option>
						<option value="décembre">décembre</option>
					</select>
					<select
						defaultValue={selectedYear}
						onChange={e => setSelectedYear(e.target.value)}
						name="year"
						id="year"
						className="my-2 cursor-pointer rounded-lg border bg-gray-100 p-3 text-sm capitalize outline-none dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					>
						<option value="" disabled>
							Année
						</option>
						{years.map(x => (
							<option key={x} value={x}>
								{x}
							</option>
						))}
					</select>
					<button
						type="submit"
						className="my-2 rounded-lg bg-green-600 p-3 text-sm font-medium text-white transition-all duration-300 hover:bg-white hover:text-green-600"
					>
						<i className="fa-solid fa-file-export"></i> Chercher
					</button>
				</div>
			</form>
			{done && retrieved.length > 0 ? (
				<CSVLink
					data={retrieved}
					filename={selectedMonth + ".csv"}
					className="mt-6 rounded-xl bg-yellow-500 p-3 px-5 text-center font-medium transition-all duration-200 hover:bg-slate-100 hover:text-teal-500 dark:hover:bg-slate-700"
				>
					Télécharger le CSV
				</CSVLink>
			) : null}
		</div>
	);
}
