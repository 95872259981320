import React from "react";
import Create from "./Create";
import Update from "./Update";
import ToggleSpecial from "./ToggleSpecial";
import Categorie from "./Categorie";

export default function Food() {
	return (
		<div className="flex w-full flex-col items-start justify-between p-6">
			<div className="flex w-full items-start justify-between flex-col md:flex-row">
				<div className="flex w-full md:w-[48%] flex-col items-center justify-center">
					<ToggleSpecial />
					<Create />
				</div>
				<div className="flex w-full md:w-[48%] flex-col items-center justify-center">
					<Categorie />
					<Update />
				</div>
			</div>
		</div>
	);
}
