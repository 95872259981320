import React from "react";
import Create from "./Create";
import ToggleSpecial from "./ToggleSpecial";
import Update from "./Update";
import Categorie from "./Categorie";

export default function Specials() {
	return (
		<div className="flex w-full flex-col items-start justify-between rounded p-6">
			<div className="flex w-full flex-col items-start justify-between md:flex-row">
				<div className="flex w-full flex-col items-center justify-center md:w-[48%]">
					<ToggleSpecial />
					<Create />
				</div>
				<div className="flex w-full flex-col items-center justify-center md:w-[48%]">
					<Categorie />
					<Update />
				</div>
			</div>
		</div>
	);
}
