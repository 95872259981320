import React, { useEffect, useState } from "react";
import {
	getDocs,
	collection,
	query,
	orderBy,
	where,
	startAfter,
	limit,
} from "firebase/firestore";
import { db } from "../../firebase";
import Rating from "react-rating";

export default function AvisFood() {
	const [reviews, setReviews] = useState([]);
	const [lastVisible, setLastVisible] = useState(5);

	const reviewsDB = collection(db, "avis");

	const q = query(
		reviewsDB,
		where("exp", "==", "restaurant"),
		orderBy("created", "desc"),
	);

/* 	const next = query(
		reviewsDB,
		where("exp", "==", "restaurant"),
		orderBy("created", "desc"),
		startAfter(lastVisible),
		limit(5)
	); */

	const getMain = async () => {
		const data = await getDocs(q);
		setLastVisible(data.docs[data.docs.length - 1]);
		setReviews(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
	};

/* 	const getMore = async () => {
		const data = await getDocs(next);
		var newData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
		setLastVisible(data.docs[data.docs.length - 1]);
		setReviews([...reviews, ...newData]);
	};
 */
	useEffect(() => {
		getMain();
	}, []);

	let serviceTotal = 0;
	let ambianceTotal = 0;
	let menuTotal = 0;
	let qualityTotal = 0;
	var length = reviews.length;
	reviews.forEach(({ serviceStars }) => (serviceTotal += serviceStars));
	reviews.forEach(({ ambianceStars }) => (ambianceTotal += ambianceStars));
	reviews.forEach(({ menuStars }) => (menuTotal += menuStars));
	reviews.forEach(({ qualityStars }) => (qualityTotal += qualityStars));

	let avg1 = Math.round((serviceTotal / length) * 10) / 10 || 0;
	let avg2 = Math.round((ambianceTotal / length) * 10) / 10 || 0;
	let avg3 = Math.round((menuTotal / length) * 10) / 10 || 0;
	let avg4 = Math.round((qualityTotal / length) * 10) / 10 || 0;
	let avg5 =
		Math.round(
			((serviceTotal + ambianceTotal + menuTotal + qualityTotal) /
				4 /
				length) *
				10
		) / 10 || 0;

	return (
		<div className="relative flex max-h-[1000px] w-full flex-col items-start justify-between overflow-y-auto overflow-x-hidden md:flex-row">
			<div
				data-aos="fade-right"
				className="sticky top-0 left-0 flex min-h-[400px] w-full flex-col items-start overflow-x-hidden rounded-2xl bg-teal-600 p-7 text-white md:w-[40%]"
			>
				<h2 className="text-2xl font-semibold tracking-wide">
					Notes des clients
				</h2>
				<hr className="my-4 h-[3px] w-[120px] border-none bg-yellow-500" />
				<span className="text-lg">
					Sur <span className="font-bold">{reviews.length}</span> avis
					:
				</span>
				<div className="mt-5 flex w-full flex-col items-start justify-center border-b pb-2">
					<div className="flex w-full items-center justify-between">
						<h3 className="text-lg font-medium">Note moyenne</h3>
						<div className="flex items-center">
							<Rating
								readonly={true}
								className="space-x-2"
								initialRating={avg5}
								emptySymbol="fa fa-star fa-2x text-lg text-gray-300"
								fullSymbol="fa fa-star fa-2x text-lg text-yellow-500"
								fractions={10}
							/>
							<span className="ml-2 self-end font-medium">
								{avg5}
							</span>
						</div>
					</div>
				</div>
				<div className="mt-3 flex w-full flex-col items-start justify-center border-b border-gray-300 pb-2">
					<div className="flex w-full items-center justify-between">
						<h3 className="text-lg font-medium">Service</h3>
						<div className="flex items-center">
							<Rating
								readonly={true}
								className="space-x-2"
								initialRating={avg1}
								emptySymbol="fa fa-star fa-2x text-lg text-gray-300"
								fullSymbol="fa fa-star fa-2x text-lg text-yellow-500"
								fractions={10}
							/>
							<span className="ml-2 self-end font-medium">
								{avg1}
							</span>
						</div>
					</div>
				</div>
				<div className="mt-3 flex w-full flex-col items-start justify-center border-b border-gray-300 pb-2">
					<div className="flex w-full items-center justify-between">
						<h3 className="text-lg font-medium">Ambiance</h3>
						<div className="flex items-center">
							<Rating
								readonly={true}
								className="space-x-2"
								initialRating={avg2}
								emptySymbol="fa fa-star fa-2x text-lg text-gray-300"
								fullSymbol="fa fa-star fa-2x text-lg text-yellow-500"
								fractions={10}
							/>
							<span className="ml-2 self-end font-medium">
								{avg2}
							</span>
						</div>
					</div>
				</div>
				<div className="mt-3 flex w-full flex-col items-start justify-center border-b border-gray-300 pb-2">
					<div className="flex w-full items-center justify-between">
						<h3 className="text-lg font-medium">Menu</h3>
						<div className="flex items-center">
							<Rating
								readonly={true}
								className="space-x-2"
								initialRating={avg3}
								emptySymbol="fa fa-star fa-2x text-lg text-gray-300"
								fullSymbol="fa fa-star fa-2x text-lg text-yellow-500"
								fractions={10}
							/>
							<span className="ml-2 self-end font-medium">
								{avg3}
							</span>
						</div>
					</div>
				</div>
				<div className="mt-3 flex w-full flex-col items-start justify-center">
					<div className="flex w-full items-center justify-between">
						<h3 className="text-lg font-medium">Qualité/Prix</h3>
						<div className="flex items-center">
							<Rating
								readonly={true}
								className="space-x-2"
								initialRating={avg4}
								emptySymbol="fa fa-star fa-2x text-lg text-gray-300"
								fullSymbol="fa fa-star fa-2x text-lg text-yellow-500"
								fractions={10}
							/>
							<span className="ml-2 self-end font-medium">
								{avg4}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div
				data-aos="fade-left"
				className="mt-10 w-full overflow-x-hidden pr-4 text-black md:mt-0 md:w-[55%]"
			>
				{reviews.map(avis => (
					<div key={avis.id} className="mb-5  pb-3">
						<div className="flex items-start justify-between">
							<h3 className="mb-2 text-lg font-medium capitalize">
								{avis.name}
							</h3>
							<span className="text-sm text-gray-400">
								{avis.date}
							</span>
						</div>
						<div className="w-full space-y-2 rounded-xl bg-gray-100 p-3 px-4">
							<div className="flex items-center justify-between border-b">
								<h5 className="mr-1">Service </h5>
								<div className="flex items-center pb-px">
									<Rating
										readonly={true}
										className="space-x-1"
										initialRating={avis.serviceStars}
										emptySymbol="fa fa-star fa-2x text-base text-gray-300"
										fullSymbol="fa fa-star fa-2x text-base text-yellow-500"
										fractions={10}
									/>
								</div>
							</div>
							<div className="flex items-center justify-between border-b">
								<h5 className="mr-1">Ambiance </h5>
								<div className="flex items-center pb-px">
									<Rating
										readonly={true}
										className="space-x-1"
										initialRating={avis.ambianceStars}
										emptySymbol="fa fa-star fa-2x text-base text-gray-300"
										fullSymbol="fa fa-star fa-2x text-base text-yellow-500"
										fractions={10}
									/>
								</div>
							</div>
							<div className="flex items-center justify-between border-b">
								<h5 className="mr-1">Menu </h5>
								<div className="flex items-center pb-px">
									<Rating
										readonly={true}
										className="space-x-1"
										initialRating={avis.menuStars}
										emptySymbol="fa fa-star fa-2x text-base text-gray-300"
										fullSymbol="fa fa-star fa-2x text-base text-yellow-500"
										fractions={10}
									/>
								</div>
							</div>
							<div className="flex items-center justify-between ">
								<h5 className="mr-1">Qualité/Prix </h5>
								<div className="flex items-center pb-px">
									<Rating
										readonly={true}
										className="space-x-1"
										initialRating={avis.qualityStars}
										emptySymbol="fa fa-star fa-2x text-base text-gray-300"
										fullSymbol="fa fa-star fa-2x text-base text-yellow-500"
										fractions={10}
									/>
								</div>
							</div>
						</div>
						<div className="mt-5 italic">
							<p className={!avis.reply ? "" : "mb-3"}>
								" {avis.review} "
							</p>
							{!avis.reply ? (
								""
							) : (
								<p>
									Réponse de BBMK :<br /> {avis.reply}
								</p>
							)}
						</div>
					</div>
				))}
				{/* <div className="my-10 flex w-full items-center justify-center">
					<button
						className="rounded-full border-2 border-transparent bg-yellow-500 py-2 px-6 text-sm font-semibold uppercase text-white transition-all duration-200 hover:border-yellow-500 hover:bg-transparent hover:text-yellow-500"
						onClick={() => getMore()}
					>
						Charger plus d'avis
					</button>
				</div> */}
			</div>
		</div>
	);
}
