import React, { useEffect, useState } from "react";

import { setTheme } from "../../Utils/theme";

import { RiSunLine, RiMoonLine } from "react-icons/ri";

export default function Mobiletoggle() {
	const [togClass, setTogClass] = useState("light");
	let theme = localStorage.getItem("theme");

	const handleOnClick = () => {
		if (localStorage.getItem("theme") === "dark") {
			setTheme("light");
			setTogClass("light");
		} else if (localStorage.getItem("theme") === "light") {
			setTheme("dark");
			setTogClass("dark");
		}
	};

	useEffect(() => {
		if (localStorage.getItem("theme") === "dark") {
			setTogClass("dark");
		} else if (localStorage.getItem("theme") === "light") {
			setTogClass("light");
		}
	}, [theme]);

	return (
		<div className="darkModeToggle flex items-center">
			<button
				type="button"
				onClick={() => handleOnClick()}
				className="group flex items-center justify-center rounded-full bg-transparent p-1 text-slate-600 transition-all duration-300 hover:bg-teal-500 hover:text-white dark:hover:text-white dark:text-slate-300 dark:hover:bg-teal-400"
			>
				{togClass === "light" ? <RiSunLine className="text-2xl" /> : <RiMoonLine className="text-2xl " />}
			</button>
		</div>
	);
}
