import React, { useState } from "react";
import axios from "axios";

export default function ContactForm() {
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [tel, setTel] = useState();
	const [object, setObject] = useState();
	const [message, setMessage] = useState();

	const [sent, setSent] = useState(false);

	const sendMessage = async e => {
		e.preventDefault();

		try {
			await axios.post("https://bbmk.herokuapp.com/send_mail", {
				tel,
				name,
				email,
				object,
				message,
			});
			setSent(true);
			e.target.reset();
		} catch (error) {
			console.log(error);
		}
	};

	if (sent) {
		setTimeout(() => {
			setSent(false);
		}, 3000);
	}

	return (
		<form onSubmit={sendMessage} className="flex w-full flex-col items-center justify-center">
			<h2 className="flex items-center justify-center text-4xl font-bold capitalize">Envoyez-nous un message</h2>
			<div className="my-3 flex w-full items-center justify-center space-x-3">
				<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
				<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
				<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
			</div>
			<input
				onChange={e => setName(e.target.value)}
				className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
				type="text"
				placeholder="Votre nom"
				required
			/>
			<input
				onChange={e => setTel(e.target.value)}
				className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
				type="tel"
				placeholder="Votre téléphone"
			/>
			<input
				onChange={e => setEmail(e.target.value)}
				className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
				type="text"
				placeholder="Votre email"
				required
			/>
			<input
				onChange={e => setObject(e.target.value)}
				className="my-3 w-full rounded-xl border-2 border-slate-300 p-3"
				type="text"
				placeholder="Sujet"
				required
			/>
			<textarea
				onChange={e => setMessage(e.target.value)}
				className="my-3 max-h-[340px] min-h-[240px] w-full rounded-xl border-2 border-slate-300 p-3"
				placeholder="Message"
				required
			/>
			<button
				className={
					sent
						? "mt-2 cursor-not-allowed self-end rounded-xl border-2 border-transparent bg-green-600 px-5 py-2 font-medium text-white transition-all duration-300"
						: "mt-2 self-end rounded-xl border-2 border-transparent bg-yellow-500 px-5 py-2 font-medium text-white transition-all duration-300 hover:border-yellow-500 hover:bg-transparent hover:text-yellow-500 hover:shadow-none"
				}
				type="submit"
			>
				{sent ? "Message envoyé" : "Envoyer le message"}
			</button>
		</form>
	);
}
