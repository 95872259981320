import { useState, useEffect } from "react";

import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export default function Home({ setShowRes, setActive }) {
	const [text, setText] = useState();
	const [title, setTitle] = useState("");
	const [paragraph, setParagraph] = useState("");

	const typography = collection(db, "website_content");
	const q = query(typography, where("position", "==", "Home"));

	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setText(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});

		return unsub;
	}, []);  //eslint-disable-line

	useEffect(() => {
		if (text) {
			setTitle(text[0].title);
			setParagraph(text[0].paragraph);
		}
	}, [text]);

	return (
		<section
			id="home"
			className="w-full min-h-[100vh] md:min-h-[80vh] flex items-center justify-center bg-red-400 relative overflow-x-hidden"
		>
			<div className="absolute top-0 left-0 w-full h-full bg-black/50 z-10"></div>
			<div className="px-4 lg:px-10 py-36 container mx-auto relative z-20 text-white">
				<div className="w-full md:w-1/2" data-aos="fade-left">
					<h1 className="text-3xl md:text-5xl font-bold">{title}</h1>
					<div className="w-full my-6 flex items-center justify-start space-x-3">
						<div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
						<div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
						<div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
					</div>
					<p className="mb-8 leading-7">{paragraph}</p>
					<button
						onClick={() => {
							setShowRes(true);
							setActive(0);
						}}
						className="bg-white text-black rounded-full px-8 py-2 text-sm font-medium border-2 border-transparent hover:bg-transparent hover:border-white hover:text-white transition-all duration-300"
					>
						Réserver une table
					</button>
				</div>
			</div>
		</section>
	);
}
