import { useState } from "react";
import AvisFood from "./AvisFood";
import AvisHotel from "./AvisHotel";

export default function Avis() {
	const [avisTab, setAvisTab] = useState(0);

	return (
		<section id="avis" className="relative mb-16 w-full overflow-x-hidden">
			<div className="container relative z-10 mx-auto flex flex-col justify-center px-4 pt-24 lg:px-10">
				<h2 className="flex items-center justify-center text-4xl font-bold capitalize">Les Avis</h2>
				<div className="my-3 mb-10 flex w-full items-center justify-center space-x-3">
					<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
					<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
					<div className="h-3 w-3 rounded-full bg-yellow-500"></div>
				</div>
				<div className="flex w-full items-center justify-center">
					<button
						onClick={() => setAvisTab(0)}
						className={
							avisTab === 0
								? "w-[230px] border-b-4 border-yellow-500 px-3 py-3 font-medium text-yellow-500 transition-all duration-300 md:px-6"
								: "w-[230px] border-b-4 px-3 py-3 font-medium transition-all duration-300 hover:text-yellow-500 md:px-6"
						}
					>
						Avis Restaurant
					</button>
					<button
						onClick={() => setAvisTab(1)}
						className={
							avisTab === 1
								? "w-[230px] border-b-4 border-yellow-500 px-3 py-3 font-medium text-yellow-500 transition-all duration-300 md:px-6"
								: "w-[230px] border-b-4 px-3 py-3 font-medium transition-all duration-300 hover:text-yellow-500 md:px-6"
						}
					>
						Avis Hotel
					</button>
				</div>
				<div className="mt-10 flex w-full items-center justify-center">
					{avisTab === 0 && <AvisFood />}
					{avisTab === 1 && <AvisHotel />}
				</div>
			</div>
		</section>
	);
}
