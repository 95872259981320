import { useRef, useState } from "react";

import useLocalStorage from "../../Hooks/useLocalStorage";

import { DateTime } from "luxon";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import { db } from "../../firebase";
import Rating from "react-rating";

export default function RatingForms() {
	const ratingDB = collection(db, "avis");

	const [serviceRating, setServiceRating] = useState(0);
	const [ambianceRating, setAmbianceRating] = useState(0);
	const [menuRating, setMenuRating] = useState(0);
	const [qPrixRating, setqPrixRating] = useState(0);
	const [chambreRating, setChambreRating] = useState(0);

	const [tab, setTab] = useState(0);

	const [sent, setSent] = useLocalStorage("alreadyDone", false);

	const [error, setError] = useState("");

	var dt = DateTime.now().setLocale("fr").toLocaleString(DateTime.DATE_FULL);

	const nameRef = useRef();
	const reviewRef = useRef();

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const email = urlParams.get("email");

	const handleRating = async e => {
		e.preventDefault();

		if (!nameRef.current.value) {
			return setError("Nom pas valide.");
		}

		if (
			serviceRating === 0 ||
			ambianceRating === 0 ||
			menuRating === 0 ||
			qPrixRating === 0
		) {
			return setError("N'oubliez pas les étoiles!");
		}

		if (!reviewRef.current.value) {
			return setError("Message obligatoire.");
		}

		if (
			reviewRef.current.value.includes("merde ") ||
			reviewRef.current.value.includes("connard ") ||
			reviewRef.current.value.includes("pute ") ||
			reviewRef.current.value.includes("bitcoin") ||
			reviewRef.current.value.includes("crypto") ||
			reviewRef.current.value.includes("http") ||
			reviewRef.current.value.includes("www")
		) {
			return setError("Message pas valide.");
		}

		try {
			await addDoc(ratingDB, {
				name: nameRef.current.value,
				email: email,
				serviceStars: serviceRating,
				ambianceStars: ambianceRating,
				menuStars: menuRating,
				qualityStars: qPrixRating,
				review: reviewRef.current.value,
				exp: "restaurant",
				date: dt,
				reply: "",
				created: serverTimestamp(),
			});
			e.target.reset();
			setServiceRating(0);
			setAmbianceRating(0);
			setMenuRating(0);
			setqPrixRating(0);
			setSent(true);
		} catch (error) {
			console.log(error);
		}
	};

	const handleRatingHotel = async e => {
		e.preventDefault();

		if (!nameRef.current.value) {
			return setError("Nom pas valide.");
		}

		if (serviceRating === 0 || chambreRating === 0) {
			return setError("N'oubliez pas les étoiles!");
		}

		if (!reviewRef.current.value) {
			return setError("Message obligatoire.");
		}

		if (
			reviewRef.current.value.includes("merde ") ||
			reviewRef.current.value.includes("connard ") ||
			reviewRef.current.value.includes("pute ") ||
			reviewRef.current.value.includes("bitcoin") ||
			reviewRef.current.value.includes("crypto") ||
			reviewRef.current.value.includes("http") ||
			reviewRef.current.value.includes("www")
		) {
			return setError("Message pas valide.");
		}

		try {
			await addDoc(ratingDB, {
				name: nameRef.current.value,
				serviceStars: serviceRating,
				chambreStars: chambreRating,
				qualityStars: qPrixRating,
				review: reviewRef.current.value,
				exp: "hotel",
				date: dt,
				created: serverTimestamp(),
			});
			e.target.reset();
			setServiceRating(0);
			setChambreRating(0);
			setqPrixRating(0);
			setSent(true);
		} catch (error) {
			console.log(error);
		}
	};

	const changeStar1 = rate => {
		setServiceRating(rate);
	};

	const changeStar2 = rate => {
		setAmbianceRating(rate);
	};

	const changeStar3 = rate => {
		setMenuRating(rate);
	};

	const changeStar4 = rate => {
		setqPrixRating(rate);
	};

	const changeStar5 = rate => {
		setChambreRating(rate);
	};

	if (error.length >= 2) {
		setTimeout(() => {
			setError("");
		}, 4000);
	}

	return (
		<div className="flex w-full flex-col items-center justify-center">
			<h1 className="logo mt-10 text-5xl text-yellow-500">BBMK</h1>
			<h4 className="mt-4 text-lg font-medium uppercase tracking-widest">
				restaurant - bar - hotel
			</h4>
			{sent ? (
				""
			) : (
				<div className="mt-4 flex w-full items-center justify-center text-black">
					<span className="mr-4">
						Choississez votre expérience :{" "}
					</span>
					<label htmlFor="restaurant">
						<input
							type="radio"
							name="exp"
							id="restaurant"
							defaultChecked
							value={0}
							onClick={() => setTab(0)}
						/>
						<span className="ml-2">Restaurant</span>
					</label>
					<label htmlFor="hotel">
						<input
							type="radio"
							name="exp"
							id="hotel"
							value={1}
							onClick={() => setTab(1)}
							className="ml-6"
						/>
						<span className="ml-2">Hotel</span>
					</label>
				</div>
			)}

			{tab === 0 && (
				<form
					onSubmit={handleRating}
					className={
						sent
							? "mt-10 flex h-[0px] w-full flex-col items-center justify-center overflow-hidden transition-all duration-300 ease-in-out md:w-[40%]"
							: "mt-10 flex h-[850px] w-full flex-col items-center justify-center overflow-hidden border-2 border-yellow-500 p-7 transition-all duration-300 ease-in-out md:w-[40%]"
					}
				>
					<h2 className="mb-6 text-2xl font-bold text-black">
						Laissez votre avis pour le restaurant
					</h2>
					<div className="w-full">
						<input
							ref={nameRef}
							type="text"
							placeholder="Votre nom"
							required
							className="w-full border bg-transparent p-3 text-black"
						/>
						<div className="my-3 flex flex-col items-center justify-center space-y-4">
							<h3 className="text-lg font-medium text-black ">
								Service
							</h3>
							<div className="flex items-center justify-center">
								<Rating
									className="space-x-3"
									onChange={value => changeStar1(value)}
									initialRating={serviceRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>
							<h3 className="mb-2 text-lg font-medium text-black">
								Ambiance
							</h3>
							<div className="flex items-center justify-center">
								<Rating
									className="space-x-3"
									onChange={value => changeStar2(value)}
									initialRating={ambianceRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>
							<h3 className="mb-2 text-lg font-medium text-black">
								Menu
							</h3>
							<div className="flex items-center justify-center">
								<Rating
									className="space-x-3"
									onChange={value => changeStar3(value)}
									initialRating={menuRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>
							<h3 className="mb-2 text-lg font-medium text-black">
								Qualité/Prix
							</h3>
							<div className="flex items-center justify-center">
								<Rating
									className="space-x-3"
									onChange={value => changeStar4(value)}
									initialRating={qPrixRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>
						</div>
					</div>
					<textarea
						ref={reviewRef}
						placeholder="Avis"
						required
						className="mt-3 min-h-[180px] w-full border bg-transparent p-3 text-black"
					/>
					<button
						type="submit"
						className="mt-5 w-full border border-transparent bg-yellow-500 py-3 text-lg font-medium text-white transition-all duration-300 ease-in-out hover:border-yellow-500 hover:bg-transparent hover:text-yellow-500"
					>
						{error === "" ? "Publier l'avis" : <span>{error}</span>}
					</button>
				</form>
			)}

			{tab === 1 && (
				<form
					onSubmit={handleRatingHotel}
					className={
						sent
							? "mt-10 flex h-[0px] w-full flex-col items-center justify-center overflow-hidden transition-all duration-300 ease-in-out md:w-[40%]"
							: "mt-10 flex h-[750px] w-full flex-col items-center justify-center overflow-hidden border-2 border-yellow-500 p-7 transition-all duration-300 ease-in-out md:w-[40%]"
					}
				>
					<h2 className="mb-6 text-2xl font-bold text-black">
						Laissez votre avis pour l'hotel
					</h2>
					<div className="w-full">
						<input
							ref={nameRef}
							type="text"
							placeholder="Votre nom"
							required
							className="w-full border bg-transparent p-3 text-black"
						/>
						<div className="my-3 flex flex-col items-center justify-center space-y-4">
							<h3 className="text-lg font-medium text-black ">
								Service
							</h3>
							<div className="flex items-center justify-center">
								<Rating
									className="space-x-3"
									onChange={value => changeStar1(value)}
									initialRating={serviceRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>
							<h3 className="mb-2 text-lg font-medium text-black">
								Chambre
							</h3>
							<div className="flex items-center justify-center">
								<Rating
									className="space-x-3"
									onChange={value => changeStar5(value)}
									initialRating={chambreRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>

							<h3 className="mb-2 text-lg font-medium text-black">
								Qualité/Prix
							</h3>
							<div className="flex items-center justify-center space-x-3">
								<Rating
									className="space-x-3"
									onChange={value => changeStar4(value)}
									initialRating={qPrixRating}
									emptySymbol="fa fa-star fa-2x text-gray-300"
									fullSymbol="fa fa-star fa-2x text-yellow-500"
									fractions={2}
								/>
							</div>
						</div>
					</div>
					<textarea
						ref={reviewRef}
						placeholder="Avis"
						required
						className="mt-3 min-h-[180px] w-full border bg-transparent p-3 text-black"
					/>
					<button
						type="submit"
						className="mt-5 w-full border border-transparent bg-yellow-500 py-3 text-lg font-medium text-white transition-all duration-300 ease-in-out hover:border-yellow-500 hover:bg-transparent hover:text-yellow-500"
					>
						{error === "" ? "Publier l'avis" : <span>{error}</span>}
					</button>
				</form>
			)}

			<div
				className={
					sent
						? "visible-check flex h-[250px] flex-col items-center justify-center opacity-100 transition-all duration-300 ease-in-out"
						: "flex h-[0px] flex-col items-center justify-center overflow-hidden opacity-0 transition-all duration-300 ease-in-out"
				}
			>
				<svg
					className="checkmark"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 52 52"
				>
					<circle
						className="checkmark__circle"
						cx="26"
						cy="26"
						r="25"
						fill="none"
					/>
					<path
						className="checkmark__check"
						fill="none"
						d="M14.1 27.2l7.1 7.2 16.7-16.8"
					/>
				</svg>
				<h4 className="my-7 text-2xl font-medium">
					Merci pour votre commentaire!
				</h4>
			</div>
		</div>
	);
}
