export const images = [
"img/photos/img_1.webp",
"img/photos/img_2.webp",
"img/photos/img_3.webp",
"img/photos/img_4.webp",
"img/photos/img_5.webp",
"img/photos/img_6.webp",
"img/photos/img_7.webp",
"img/photos/img_8.webp",
"img/photos/img_9.webp",
"img/photos/img_11.webp",
"img/photos/img_12.webp",
"img/photos/img_13.webp",
"img/photos/img_14.webp",
"img/photos/img_15.webp",
"img/photos/img_16.webp",
"img/photos/img_17.webp",
"img/photos/img_18.webp",
"img/photos/img_19.webp",
];
