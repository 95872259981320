import React from "react";
import { Link } from "react-router-dom";

export default function CGU() {
    return (
        <section className="container relative mx-auto flex min-h-screen flex-col items-center justify-center px-4 pt-36 md:px-0">
            <h1 className="logo mb-8 text-center text-5xl text-yellow-500 md:mb-16">BBMK</h1>
            <Link to="/" className="absolute top-24 left-4 underline transition-all duration-300 hover:text-yellow-500 md:left-0">
                {" "}
                <i className="fa-solid fa-arrow-left mr-2 text-yellow-500"></i>
                Revenir à l 'accueil
            </Link>
            <div className="mb-10 space-y-5">
                <h1 className="text-3xl font-semibold">Mentions Légales</h1>
                <p>
                    Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir ses
                    pages. En vous connectant sur ce site, vous acceptez sans réserve les présentes modalités. Aussi, conformément
                    à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les
                    responsables du présent site internet sont :
                </p>
                <h2 className="text-2xl font-medium">Editeur du Site</h2>
                <p>
                    BBMK pour le compte de la SARL BBMK au capital de 10 000.00 € enregistrée au RCS Evry sous le numéro 910 743
                    186. <br /> Numéro de SIRET : 91074318600017 <br /> N° TVA intracommunautaire : FR15910743186 <br /> N° de
                    téléphone : 01 60 83 10 22 <br /> Adresse @mail : contact@bbmk.fr <br /> Siège social: 1 rue Croix Boissée,
                    91810 VERT-LE-GRAND
                </p>
                <h2 className="text-2xl font-medium">Hébergement</h2>
                <p>
                    Hébergeur : 1&1 Ionos <br />
                    Site internet : www.ionos.com
                </p>
                <h2 className="text-2xl font-medium">Conditions d'utilisation</h2>
                <p>
                    Ce site est proposé en différents langages web (HTML, Javascript, CSS, etc…) pour un meilleur confort
                    d’utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes
                    comme Safari, Firefox, Google Chrome, etc… <br /> BBMK met en œuvre tous les moyens dont elle dispose, pour
                    assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou
                    omissions peuvent survenir.
                </p>
                <h2 className="text-2xl font-medium">Données</h2>
                <p>
                    Le site internet peut vous demander des informations personnelles comme votre adresse e-mail et numéro de
                    téléphone. Ces informations pourraient être utilisées pour vous contacter par e-mail ou numéro de téléphone.
                </p>
                <h2 className="text-2xl font-medium">Limitation contractuelles sur les données</h2>
                <p>
                    Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes
                    périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. <br /> Tout contenu
                    téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ne
                    saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une
                    quelconque perte de données consécutives au téléchargement. De plus, l’utilisateur du site s’engage à accéder
                    au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière
                    génération mis-à-jour. Les liens hypertextes mis en place dans le cadre du présent site internet en direction
                    d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de BBMK pour le
                    compte de la SARL BBMK.
                </p>
                <h2 className="text-2xl font-medium">Propriété intellectuelle</h2>
                <p>
                    Tout le contenu du présent site incluant, de façon non limitative, les graphismes, images, textes, vidéos,
                    animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société
                    à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. <br />{" "}
                    Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de
                    ces différents éléments est strictement interdite sans l’accord exprès par écrit de /. Cette représentation ou
                    reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2
                    et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une
                    contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires
                    des Contenus copiés pourraient intenter une action en justice à votre encontre.
                </p>
                <h2 className="text-2xl font-medium">La remise de note</h2>
                <p>
                    Article 1er : « Toute prestation de service doit faire l'objet, dès qu'elle a été rendue et en tout état de
                    cause avant paiement du prix, de la délivrance d'une note lorsque le prix de la prestation est supérieur ou
                    égal à 25 € (TVA comprise). Pour les prestations de service dont le prix est inférieur à 25 € (TVA comprise),
                    la délivrance d'une note est facultative, mais celle-ci doit être remise au client s'il la demande. »
                </p>
                <h2 className="text-2xl font-medium">Donnés téléphoniques</h2>
                <p>
                    L'article L.223-2 du Code de la consommation : <br /> <br /> « Lorsqu'un professionnel est amené à recueillir
                    auprès d'un consommateur des données téléphoniques, il l'informe de son droit à s'inscrire sur la liste
                    d'opposition au démarchage téléphonique. Lorsque ce recueil d'information se fait à l'occasion de la
                    conclusion d'un contrat, le contrat mentionne, de manière claire et compréhensible, l'existence de ce droit
                    pour le consommateur. » .
                </p>
                <h2 className="text-2xl font-medium">Droit de rétractation</h2>
                <p>
                    L'article L.221-28 du Code de la consommation : <br /> <br /> « Le droit de rétractation ne peut être exercé
                    pour les contrats : <br /> 12° De prestations de services d'hébergement, autres que d'hébergement résidentiel,
                    de services de transport de biens, de locations de voitures, de restauration ou d'activités de loisirs qui
                    doivent être fournis à une date ou à une période déterminée; [...] ».
                </p>
                <p>
                    L'article L.221-5 10° du Code de la consommation : <br /> <br /> «I. - Préalablement à la conclusion d'un
                    contrat de vente de biens ou de fourniture de services, de contenu numérique ou de services numériques, le
                    professionnel fournit au consommateur, de manière lisible et compréhensible, les informations suivantes : 10°
                    Lorsque le droit de rétractation ne peut être exercé en application de l'article L. 221-28, l'information
                    selon laquelle le consommateur ne bénéficie pas de ce droit ou, le cas échéant, les circonstances dans
                    lesquelles il le perd ; [...] »
                </p>
                <h2 className="text-2xl font-medium">Plateforme de règlement en ligne des litiges</h2>
                <p>
                    L'article L.616-2 du Code de la consommation : <br /> <br /> « Le cas échéant, il informe en outre le
                    consommateur des dispositions prises pour mettre en œuvre l'article 14 du règlement (UE) n° 524/2013 du
                    Parlement européen et du Conseil du 21 mai 20173 relatif au règlement en ligne des litiges de consommation et
                    modifiant le règlement (CEE) n° 2006/2004 et la directive n° 2009/22/CE (règlement relatif au RLLC),. »
                </p>
                <h2 className="text-2xl font-medium">Le médiateur de la consommation</h2>
                <p>
                    Coordonnées du médiateur : <br /> <br />
                    Médiation Solution , <br />
                    222 chemin de la Bergerie <br />
                    Tél: +33 04 82 53 93 06 <br />
                    contact@sasmediationsolution-conso.fr
                </p>
            </div>
        </section>
    );
}
