import React from "react";

import ContactForm from "./ContactForm";

export default function Contact() {
	return (
		<section id="contact" className="my-16 w-full overflow-x-hidden py-10">
			<div className="container mx-auto my-24 flex flex-col items-center justify-between px-4 md:flex-row lg:px-10">
				<div  className="w-full md:w-[50%]">
					<ContactForm />
				</div>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.9399728874882!2d2.3569054156642952!3d48.5726984792606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b850c0381431fb9!2sBBMK!5e0!3m2!1sen!2sfr!4v1648547268155!5m2!1sen!2sfr"
					data-aos="fade-up"
					className="mt-10 h-[550px] w-full rounded-2xl shadow-2xl shadow-teal-700 md:mt-0 md:w-[45%]"
					title="Google Maps"
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
				></iframe>
			</div>
		</section>
	);
}
