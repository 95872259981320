import React, { useState, useEffect } from "react";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

import RestaurantBtn from "./RestaurantBtn";

import { HiUserGroup } from "react-icons/hi";
import { GiKnifeFork, GiTable } from "react-icons/gi";
import { BiChair } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";

import { getDocs, collection, query, where, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

flatpickr.localize(French);

export default function ClientsRes() {
	const [customer, setCustomer] = useState([]);
	const [picker, setPicker] = useState("");
	const [jour, setJour] = useState();

	const [totalSeats, setTotalSeats] = useState(0);
	const [childrenSeats, setChildrenSeats] = useState(0);
	const [takenTables, setTakenTables] = useState(0);

	const [deleted, setDeleted] = useState(false);

	const customers = collection(db, "res_restaurant");
	let arr = [];

	const options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "2-digit",
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (!picker) {
			return;
		}

		let stringified = new Date(picker).toLocaleDateString("fr-FR", options);

		const q = query(customers, where("date", "==", stringified), where("jour", "==", jour));

		try {
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach(doc => {
				arr.push({ ...doc.data(), id: doc.id });
			});
			setCustomer(arr);
		} catch (err) {
			console.log(err);
		}
	};

	const onDelete = async docID => {
		const chosenDoc = doc(db, "res_restaurant", docID);
		await deleteDoc(chosenDoc);

		setDeleted(true);
	};

	if (deleted) {
		setTimeout(() => {
			setDeleted(false);
		}, 3000);
	}

	useEffect(() => {
		if (customer) {
			setTotalSeats(customer.reduce((acc, cur) => acc + parseInt(cur.adultes), 0));
			setChildrenSeats(customer.reduce((acc, cur) => acc + parseInt(cur.enfant), 0));
			setTakenTables(customer.reduce((acc, cur) => acc + parseInt(cur.placeOccupe), 0));
		}
	}, [customer]);

	const flatpickrOptions = { mode: "single", dateFormat: "d/m/Y", ...French, firstDayOfWeek: 3 };

	return (
		<div className="flex h-full w-full flex-col items-center justify-start">
			<div className="flex w-full flex-col items-start justify-between p-8">
				<form onSubmit={handleSubmit} className="flex w-full items-center justify-end">
					<div className="flex flex-col items-center justify-between md:flex-row">
						<div className="flex items-center">
							<label htmlFor="date" className="flex items-center text-sm dark:text-white">
								Date
								<Flatpickr
									className="ml-3 w-[250px] cursor-pointer rounded-lg border p-2 outline-none focus:ring-1 focus:ring-teal-600 dark:border-slate-500 dark:bg-slate-700"
									id="date"
									placeholder="Filtrer par date"
									value={picker}
									options={flatpickrOptions}
									onChange={date => setPicker(date)}
								/>
							</label>
							<button type="button" onClick={() => setPicker()} className="ml-3">
								<MdDeleteForever className="text-2xl dark:text-white" />
							</button>
						</div>
						<ul className="mt-6 flex w-full justify-end md:mt-0 md:items-center md:justify-center md:px-7">
							<li className="relative">
								<input
									className="peer sr-only"
									type="radio"
									value="midi"
									name="resa"
									id="res"
									required
									onChange={e => setJour(e.target.value)}
								/>
								<label
									className="flex cursor-pointer items-center justify-center rounded-l-lg bg-slate-200 p-2  px-5 py-2 text-sm transition-all duration-200 focus:outline-none peer-checked:bg-teal-500 peer-checked:text-white peer-checked:shadow-lg peer-checked:shadow-teal-700 dark:bg-slate-600 dark:text-white"
									htmlFor="res"
								>
									Midi
								</label>
							</li>
							<li className="relative">
								<input
									className="peer sr-only"
									type="radio"
									name="resa"
									id="resHotel"
									value="soir"
									onChange={e => setJour(e.target.value)}
								/>
								<label
									className="flex cursor-pointer items-center justify-center rounded-r-lg bg-slate-200 p-2  px-5 py-2 text-sm transition-all duration-200 focus:outline-none peer-checked:bg-teal-500 peer-checked:text-white peer-checked:shadow-lg peer-checked:shadow-teal-700 dark:bg-slate-600 dark:text-white"
									htmlFor="resHotel"
								>
									Soir
								</label>
							</li>
							<li>
								<button
									type="submit"
									className="ml-6 rounded-lg border border-transparent bg-green-600 p-2 text-sm font-medium text-white transition-all duration-300 hover:border-green-600 hover:bg-transparent hover:text-green-600 md:w-full"
								>
									<i className="fa-solid fa-magnifying-glass"></i>
								</button>
							</li>
						</ul>
					</div>
				</form>
				<div className="mt-6 grid w-full grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
					<div className="flex cursor-default items-center justify-start rounded-xl border p-3 transition-all duration-200 hover:shadow-md dark:border-slate-500 dark:hover:shadow-lg dark:hover:shadow-teal-800">
						<span className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-teal-100">
							<HiUserGroup className="text-2xl text-teal-700" />
						</span>
						<div className="ml-4 flex flex-col">
							<span className="text-sm font-medium tracking-wide text-slate-600 dark:text-slate-200">
								{totalSeats + childrenSeats}
							</span>
							<span className="text-xs font-semibold text-slate-400">Total de personnes</span>
						</div>
					</div>
					<div className="flex cursor-default items-center justify-start rounded-xl border p-3 transition-all duration-200 hover:shadow-md dark:border-slate-500 dark:hover:shadow-lg dark:hover:shadow-teal-800">
						<span className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-blue-100">
							<GiKnifeFork className="text-2xl text-blue-700" />
						</span>
						<div className="ml-4 flex flex-col">
							<span className="text-sm font-medium tracking-wide text-slate-600 dark:text-slate-200">
								{takenTables}
							</span>
							<span className="text-xs font-semibold text-slate-400">Places occupées</span>
						</div>
					</div>
					<div className="flex cursor-default items-center justify-start rounded-xl border p-3 transition-all duration-200 hover:shadow-md dark:border-slate-500 dark:hover:shadow-lg dark:hover:shadow-teal-800">
						<span className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-orange-100">
							<BiChair className="text-2xl text-orange-700" />
						</span>
						<div className="ml-4 flex flex-col">
							<span className="text-sm font-medium tracking-wide text-slate-600 dark:text-slate-200">
								{50 - takenTables}
							</span>
							<span className="text-xs font-semibold text-slate-400">Places restantes</span>
						</div>
					</div>
					<div className="flex cursor-default items-center justify-start rounded-xl border p-3 transition-all duration-200 hover:shadow-md dark:border-slate-500 dark:hover:shadow-lg dark:hover:shadow-teal-800">
						<span className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-purple-100">
							<GiTable className="text-2xl text-purple-700" />
						</span>
						<div className="ml-4 flex flex-col">
							<span className="text-sm font-medium tracking-wide text-slate-600 dark:text-slate-200">
								{customer.length === 0
									? "Aucune"
									: customer.map(n => <span key={n.id}>n° {n.table}, </span>)}
							</span>
							<span className="text-xs font-semibold text-slate-400">Table occupées</span>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-8 flex w-full flex-col items-start justify-start overflow-x-auto overflow-y-hidden">
				{customer.map((x, i) => (
					<RestaurantBtn key={i} customer={x} onDelete={onDelete} />
				))}
			</div>
		</div>
	);
}
