import React, { useContext } from "react";
import { authContext } from "../../Context/AuthContext";

import Admin from "./Admin";

export default function Main() {
	const { user } = useContext(authContext);

	if (!user) return "...";

	return (
		<>
			<Admin />
		</>
	);
}
