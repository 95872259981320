import { useState, useRef, useEffect } from "react";

import {
	getDocs,
	onSnapshot,
	collection,
	doc,
	updateDoc,
	deleteDoc,
	query,
	where,
} from "firebase/firestore";
import { db } from "../../../firebase";

export default function Update() {
	const [categories, setCategories] = useState([]);

	const [name, setName] = useState();
	const [price, setPrice] = useState();
	const [desc, setDesc] = useState();
	const [categorie, setCategorie] = useState("");
	const [docID, setDocID] = useState();

	const [search, setSearch] = useState();

	const [updated, setUpdated] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [error, setError] = useState("");

	const menu = collection(db, "menu_brunch");

	const form = useRef();
	const input = useRef();

	const handleSearch = async e => {
		e.preventDefault();
		const q = query(menu, where("name", "==", search.toLowerCase()));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(doc => {
			setName(doc.data().name);
			setPrice(doc.data().price);
			setDesc(doc.data().description);
			setCategorie(doc.data().categorie);
			setDocID(doc.id);
		});
	};

	const handleSubmit = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "menu_brunch", docID);

		try {
			await updateDoc(chosenDoc, {
				categorie: categorie,
				name: name.toLowerCase(),
				price: price,
				description: desc,
			});
			setUpdated(true);
		} catch (err) {
			console.log(err);
		}
	};

	const handleDelete = async e => {
		e.preventDefault();

		if (!search) {
			return setError("Recherche pas valide");
		}

		const chosenDoc = doc(db, "menu_brunch", docID);
		await deleteDoc(chosenDoc);

		setDeleted(true);
	};

	if (updated) {
		setTimeout(() => {
			setUpdated(false);
		}, 3000);
	}

	if (deleted) {
		setTimeout(() => {
			setDeleted(false);
		}, 3000);
	}

	if (error) {
		setTimeout(() => {
			setError("");
		}, 3000);
	}

	const webContent = collection(db, "website_content");
	const q = query(webContent, where("type", "==", "categorie_brunch"));
	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setCategories(
				snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
			);
		});

		return unsub;
	}, []);

	if (categories.length === 0) {
		return <div>Chargement...</div>;
	}

	return (
		<div className="flex w-full flex-col items-center justify-center">
			<h2 className="my-4 self-start text-2xl font-semibold dark:text-white">Mettre à jour</h2>
			<form onSubmit={handleSearch} className="flex w-full items-center justify-between">
				<input
					type="text"
					onChange={e => setSearch(e.target.value)}
					required
					placeholder="Chercher plât"
					className="my-2 w-[70%] rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
				/>
				<button
					type="submit"
					className="my-2 md:w-1/4 rounded-lg bg-green-600 p-3 text-sm font-medium text-white transition-all duration-300 hover:bg-white hover:text-green-600"
				>
					Chercher <i className="fa-solid fa-magnifying-glass ml-1"></i>
				</button>
			</form>
			<form ref={form} onSubmit={handleSubmit} className="flex w-full flex-col items-center justify-center">
				<div className="flex w-full items-center justify-between">
					<select
						className="my-2 w-[33%] rounded-lg border bg-white p-3 text-sm capitalize dark:border-slate-500 dark:bg-gray-700 dark:text-white"
						onChange={e => setCategorie(e.target.value)}
						multiple={false}
						defaultValue=""
						name="categorie"
						id="categorie"
					>
						<option value="" disabled>
							Categorie
						</option>
						{categories[0].categories.map(cat => (
							<option key={cat} value={cat} selected={cat === categorie}>
								{cat}
							</option>
						))}
					</select>
					<input
						ref={input}
						onChange={e => setName(e.target.value)}
						type="text"
						value={name}
						placeholder="Nom plat"
						className="my-2 w-[65%] rounded-lg border bg-white p-3 text-sm capitalize dark:border-slate-500 dark:bg-gray-700 dark:text-white"
						required
					/>
				</div>
				<input
					value={price}
					onChange={e => setPrice(e.target.value)}
					placeholder="Prix"
					className="my-2 w-full rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					required
				/>

				<textarea
					value={desc}
					onChange={e => setDesc(e.target.value)}
					placeholder="Description"
					className="my-2 h-[300px] w-full rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					required
				/>

				<button
					type="submit"
					className={
						updated
							? "mt-4 w-full rounded-full bg-green-500 p-3 text-sm font-medium text-white"
							: "mt-4 w-full rounded-full bg-yellow-500 p-3 text-sm font-medium"
					}
				>
					{updated ? (
						<span>
							Plât bien mis à jour <i className="fa-solid fa-circle-check"></i>
						</span>
					) : (
						"Mettre à jour"
					)}
				</button>
			</form>
			{docID ? (
				<form onSubmit={handleDelete} className="w-full">
					<button type="submit" className="mt-4 w-full rounded bg-red-600 p-3 font-medium text-white">
						{error ? (
							<span>{error}</span>
						) : (
							<span>{deleted ? "Supprimé de la BD!" : "Supprimer le plât"}</span>
						)}
					</button>
				</form>
			) : (
				""
			)}
		</div>
	);
}
