import { useState, useEffect } from "react";

import { collection, addDoc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

export default function Create() {
	const [categories, setCategories] = useState([]);

	const [item, setItem] = useState("");
	const [price, setPrice] = useState("");
	const [description, setDescription] = useState("");
	const [chosenCategorie, setChosenCategorie] = useState();

	const menu = collection(db, "menu_soir");
	const webContent = collection(db, "website_content");
	const [added, setAdded] = useState(false);

	console.log(chosenCategorie);

	const createItem = async e => {
		e.preventDefault();

		await addDoc(menu, {
			categorie: chosenCategorie,
			name: item.toLowerCase(),
			price: price,
			description: description,
		});

		e.target.reset();
		setAdded(true);
	};

	const q = query(webContent, where("type", "==", "categorie_soir"));

	useEffect(() => {
		const unsub = onSnapshot(q, snapshot => {
			setCategories(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});

		return unsub;
	}, []);

	if (added) {
		setTimeout(() => {
			setAdded(false);
		}, 3000);
	}

	if (categories.length < 1) return "";

	return (
		<>
			<form onSubmit={createItem} className="flex w-full flex-col items-center justify-center">
				<h2 className="my-4 self-start text-2xl font-semibold dark:text-white">Ajouter un plat (menu soir)</h2>
				<div className="flex w-full items-center justify-between">
					<select
						className="my-2 w-[33%] cursor-pointer rounded-lg border bg-white p-3 text-sm capitalize outline-none dark:border-slate-500 dark:bg-gray-700 dark:text-white"
						onChange={e => setChosenCategorie(e.target.value)}
						defaultValue="None"
						name="categorie"
						id="categorie"
					>
						<option value="None" disabled>
							Categorie
						</option>
						{categories[0].categories.map(categorie => (
							<option key={categorie} value={categorie}>
								{categorie}
							</option>
						))}
					</select>
					<input
						onChange={e => setItem(e.target.value)}
						type="text"
						placeholder="Nom plat"
						className="my-2 w-[65%] rounded-lg border bg-white p-3 text-sm capitalize dark:border-slate-500 dark:bg-gray-700 dark:text-white"
						required
					/>
				</div>
				<input
					onChange={e => setPrice(e.target.value)}
					placeholder="Prix"
					className="my-2 w-full rounded-lg border bg-white p-3 text-sm capitalize dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					required
				/>
				<textarea
					onChange={e => setDescription(e.target.value)}
					placeholder="Description"
					className="my-2 h-[300px] w-full rounded-lg border bg-white p-3 text-sm capitalize dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					required
				/>

				<button
					type="submit"
					className={
						added
							? "mt-4 w-full rounded-full text-sm bg-green-500 p-3 font-medium text-white"
							: "mt-4 w-full rounded-full text-sm bg-yellow-500 p-3 font-medium"
					}
				>
					{added ? (
						<span>
							Plât ajouté <i className="fa-solid fa-circle-check"></i>
						</span>
					) : (
						"Ajouter"
					)}
				</button>
			</form>
		</>
	);
}
