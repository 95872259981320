import { useState } from "react";

import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../../../firebase";

export default function Categorie() {
	const [newCategorie, setNewCategorie] = useState("");
	const [added, setAdded] = useState(false);
	const [deleted, setDeleted] = useState(false);

	const handleSubmit = async e => {
		e.preventDefault();

		const chosenDoc = doc(db, "website_content", "mdKt9ZhIIUwyK8x1xcLK");

		try {
			await updateDoc(chosenDoc, {
				categories: arrayUnion(newCategorie.toLocaleLowerCase()),
			});
			setAdded(true);
			e.target.reset();
		} catch (err) {
			console.log(err);
		}
	};

	const handleDelete = async () => {

		if (newCategorie.length === 0) {
			return;
		}

		const chosenDoc = doc(db, "website_content", "mdKt9ZhIIUwyK8x1xcLK");
		try {
			await updateDoc(chosenDoc, {
				categories: arrayRemove(newCategorie.toLocaleLowerCase()),
			});
			setDeleted(true);
		} catch (err) {
			console.log(err);
		}
	};

	if (added) {
		setTimeout(() => {
			setAdded(false);
		}, 3000);
	}

	if (deleted) {
		setTimeout(() => {
			setDeleted(false);
		}, 3000);
	}

	return (
		<form onSubmit={handleSubmit} className="mb-6 flex w-full flex-col items-center justify-center">
			<h2 className="my-4 self-start text-2xl font-semibold dark:text-white">Ajouter une catégorie</h2>
			<div className="relative w-full">
				<input
					type="text"
					placeholder="Nom categorie"
					className="my-2 w-full rounded-lg border bg-white p-3 text-sm dark:border-slate-500 dark:bg-gray-700 dark:text-white"
					onChange={e => setNewCategorie(e.target.value)}
				/>
				<button
					type="button"
					onClick={handleDelete}
					className={
						deleted
							? "absolute right-0 my-2 rounded-lg bg-green-500 p-3 px-5 text-white"
							: "absolute right-0 my-2 rounded-lg bg-red-500 p-3 px-5 text-white"
					}
				>
					{deleted ? <i class="fa-solid fa-circle-check"></i> : <i class="fa-solid fa-trash-can"></i>}
				</button>
			</div>
			<button
				type="submit"
				className={
					added
						? "mt-4 w-full rounded-full bg-green-500 p-3 text-sm font-medium"
						: "mt-4 w-full rounded-full bg-yellow-500 p-3 text-sm font-medium"
				}
			>
				{added ? "Categorie ajoutée" : "Ajouter"}
			</button>
		</form>
	);
}
